<template>
  <div>
    <h3 ref="intro">
      Definition of Arithmetic Mean
    </h3>
    <p class="mt-2">
      In mathematics, the arithmetic mean of a set of values or data is defined as the sum total of
      all the values in the set divided by the number of values in the set.<br>
      For example, consider the following set of values:
      $$x =\{2, 4, 6, 8, 10\}$$
      The arithmetic mean for this set of values is then given by:
      $$ \bar{x} = \frac{2+4+6+8+10}{5} = 6$$
      </body>
    </p>
    <h3 ref="types">
      Significance of Arithmetic Mean
    </h3>
    <p class="mt-2">
      The arithmetic mean of a set of values is that one value if each item in the set
      can be assigned this same value while getting the same sum total.
      <br>For instance, in the above example, we can assign each entry in the set a value of 6 and we will still end up with
      the same sum total (i.e., 36).
    </p>
    <h3 ref="pg">
      MagicGraph | Finding average of numbers
    </h3>
    <p class="mt-2">
      Through this MagicGraph, you will learn, in a step by step manner, how to calculate the average of a set of numbers.
      <br> <br>
      Mary is a soccer player. She plays soccer 5 days a week (from Monday till Friday). Shown in the MagicGrap are Mary's daily soccer scores for a week. Your goal is to find Mary's daily average soccer score based on this information. Use the icons on the MagicGraph to walk through the exercise.
      <br>
      Tap on <i class="shuffler ma-1" /> icon to shuffle and generate a different set of daily scores.
      Tap on the <i class="next ma-1" /> icon to go to the next step.
      To go to the previous step, tap on the <i class="previous ma-1" /> icon. You can tap on the <i class="eraser ma-1" /> icon to erase and start over.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Average',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Finding average';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Average of Numbers',
          titleTemplate: '%s | Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively about finding the average of numbers'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
.icn{
color: var(--v-red-base);;
}
</style>
