import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeRightText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeMiddleText,
    placeSliderSwitch,
    placeShuffle,
    placeLogo,
    placeRedo,
    placeUndo,
    drawPoint,
    drawSegment,
    placeStartOver,
    createSpace,
    placeErase
} from '../Utils';
const Boxes = {
  box1: function () {
  JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.circle.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  ////////////////////////////////////////////////////////////////////
  //var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-2, 8, 8, -2],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  var brd1 = createSpace(-2,8.,-2.,8);
  makeResponsive(brd1);
  brd1.options.layer['image'] =14;
  placeTitle(brd1, 'Average of Numbers', '(Below are Mary\'s daily soccer scores for a week)');
  placeLogo(brd1);
  brd1.create('image', ['/assets/finger.svg', [-1+0.2, 6.2],[0.6, 0.6]], {opacity:1, fixed:true});
  brd1.create('image', ['/assets/finger.svg', [-2+0.2, 6-0.2],[0.6, 0.6]], {opacity:1, fixed:true, rotate:-90});
/////////////////////////////////////////////////
  var type1 = 2;
  var type2 = 3;
  var type3 = 5
  var type4 = 2
  var type5 = 3;
  var dataArr=[type1, type2, type3, type4, type5];
  var bulldog=[];
  var doberman=[];
  var greyhound=[];
  var dalmatian=[];
  var sharpei =[];
  var charts=[];
  var a=1;
  var i=1, j=1, k=1, l=1, m=1;
  var ini =function(){
  brd1.removeObject(bulldog);
  brd1.removeObject(doberman);
  brd1.removeObject(greyhound);
  brd1.removeObject(dalmatian);
  brd1.removeObject(sharpei);
  brd1.removeObject(charts);
  }
  var P1 = drawPoint(brd1, -1, 6);
  P1.setAttribute({visible:false});
  var P2 = drawPoint(brd1, 0, 5);
  P2.setAttribute({visible:false});
  var sep =drawSegment(brd1, P1, P2);
  sep.setAttribute({strokeColor:'red'});
  var bd = function(){
  for(i=1;i<=type1;i++)
   {
   bulldog[i-1] =brd1.create('image', ['/assets/ship.svg', [0.25+a*(i-1), 0.25],[0.5, 0.5]], {opacity:1, fixed:true});
  // bulldog[i-1].setLabel('=500 dogs')
  // bulldog[i-1].label.setAttribute({visible:false, offset:[0, -10], CssStyle:'fontFamily:Oswald', fontSize:12});
   bulldog[i-1].on('over', function () {this.label.setAttribute({visible:true});});
   bulldog[i-1].on('out', function () {this.label.setAttribute({visible:false});});
   }
  }
  var dm = function(){
  for(j=1;j<=type2;j++)
   {
   doberman[j-1] =brd1.create('image', ['/assets/ship.svg', [0.25+a*(j-1), 1.25],[0.5, 0.5]], {opacity:1, fixed:true});
   //doberman[j-1].setLabel('=$500')
   //doberman[j-1].label.setAttribute({visible:false, offset:[0, -10], CssStyle:'fontFamily:Oswald', fontSize:12});
   doberman[j-1].on('over', function () {this.label.setAttribute({visible:true});});
   doberman[j-1].on('out', function () {this.label.setAttribute({visible:false});});
   }
  }
  var gh = function(){
  for(k=1;k<=type3;k++)
   {
   greyhound[k-1] =brd1.create('image', ['/assets/ship.svg', [0.25+a*(k-1), 2.25],[0.5, 0.5]], {opacity:1, fixed:true});
   //greyhound[k-1].setLabel('=$500')
   //greyhound[k-1].label.setAttribute({visible:false, offset:[0, -10], CssStyle:'fontFamily:Oswald', fontSize:12});
   greyhound[k-1].on('over', function () {this.label.setAttribute({visible:true});});
   greyhound[k-1].on('out', function () {this.label.setAttribute({visible:false});});
   }
  }
  var dmt = function(){
  for(l=1;l<=type4;l++)
   {
   dalmatian[l-1] =brd1.create('image', ['/assets/ship.svg', [0.25+a*(l-1), 3.25],[0.5, 0.5]], {opacity:1, fixed:true});
   //dalmatian[l-1].setLabel('=$500')
   //dalmatian[l-1].label.setAttribute({visible:false, offset:[0, -10], CssStyle:'fontFamily:Oswald', fontSize:12});
   dalmatian[l-1].on('over', function () {this.label.setAttribute({visible:true});});
   dalmatian[l-1].on('out', function () {this.label.setAttribute({visible:false});});
   }
  }
  var sp = function(){
  for(m=1;m<=type5;m++)
   {
   sharpei[m-1] =brd1.create('image', ['/assets/ship.svg', [0.25+a*(m-1), 4.25],[0.5, 0.5]], {opacity:1, fixed:true});
  // sharpei[m-1].setLabel('=$500')
  // sharpei[m-1].label.setAttribute({visible:false, offset:[0, -10], CssStyle:'fontFamily:Oswald', fontSize:12});
   sharpei[m-1].on('over', function () {this.label.setAttribute({visible:true});});
   sharpei[m-1].on('out', function () {this.label.setAttribute({visible:false});});
   }
  }
  var cht = function(){
    charts[0] =brd1.create('chart', dataArr, {fillOpacity:1, anchorX:'left',chartStyle:'bar',labels:dataArr,width:1,dir:'vertical'});
  }
  var jm=0, mm=0, dim=0, sm=0, km=0;
  bd(); dm();gh();dmt();sp();
  /////////////////////////////////////////////////////////////////////////////////////
  var next = placeRedo(brd1);
  next.setAttribute({visible:()=>jm<2});
  next.setLabel('Tap to Go to Next Step');
  next.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  next.on('over', function (){this.label.setAttribute({visible:true});});
  next.on('out', function (){this.label.setAttribute({visible:false});});
  //////////////////////////////////////////////////////////////////////////////////
  var prev = placeUndo(brd1,'left');
  prev.setAttribute({visible:()=>jm>0});
  prev.setLabel('Tap to Go to Previous Step');
  prev.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  prev.on('over', function(){this.label.setAttribute({visible:true});});
  prev.on('out', function(){this.label.setAttribute({visible:false});});
  ////////////////////////////////////////////////////////////////////////////////
  var stover = placeErase(brd1);
  stover.setAttribute({visible:()=>jm==2});
  stover.setLabel('Tap to Start Over');
  stover.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  stover.on('over', function(){this.label.setAttribute({visible:true});});
  stover.on('out', function(){this.label.setAttribute({visible:false});});
  stover.on('down', function(){jm=0});
///////////////////////////////////////////////////////////////////////////////
  var reload = placeShuffle(brd1, 'left');
  reload.setLabel('Tap to Shuffle', 'left')
  reload.label.setAttribute({visible:false, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  reload.setAttribute({visible:()=>jm==0})
  next.on('down', function(){
    if(jm<2)
    {
    jm+=1;
    }
    else
    {
    jm=2;
    }
  });
  prev.on('down', function(){
    if(jm>0)
    {
    jm-=1;
    }
    else
    {
    jm=0;
    }
  });
  reload.on('over', function () {this.label.setAttribute({visible:true});});
  reload.on('out', function () {this.label.setAttribute({visible:false});});
  reload.on('down', function(){ini();
  type1=Math.round(Math.random()*3)+1;
  type2=Math.round(Math.random()*4)+1;
  type3=Math.round(Math.random()*4)+1;
  type4=Math.round(Math.random()*2)+1;
  type5=Math.round(Math.random()*3)+1;
  dataArr=[type1, type2, type3, type4, type5];jm=0;mm=0;dim=0;sm=0;km=0;i=1;j=1;k=1;l=1;m=1;bd();dm();gh();dmt();sp();});
  brd1.create('text', [-1+0.05, 5., 'Day'],{display:'internal',anchorX:'left', anchorY:'bottom', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  //
  brd1.create('text', [-0.05, 6, 'Score'],{display:'internal',anchorX:'right', anchorY:'top', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  //
  var john =brd1.create('text', [-1+0.5, 0.5, 'Monday'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  var mary =brd1.create('text', [-1+0.5, 1.5, 'Tuesday'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  var david =brd1.create('text', [-1+0.5, 2.5, 'Wednesday'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  var sarah = brd1.create('text', [-1+0.5, 3.5, 'Thursday'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  var katie = brd1.create('text', [-1+0.5, 4.5, 'Friday'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
  //////////////////////////////////////////////////
  brd1.create('text', [6, 0.5, function(){return '' + type1.valueOf()+' goals';}],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [6, 1., '+'],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [6, 1.5, function(){return '' + type2.valueOf()+' goals';}],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [6, 2., '+'],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [6, 2.5, function(){return '' + type3.valueOf()+' goals';}],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [6, 3., '+'],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [6, 3.5, function(){return '' + type4.valueOf()+' goals';}],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [6, 4., '+'],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [6, 4.5, function(){return '' + type5.valueOf()+' goals';}],{visible:()=>jm>=1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [6, 5.5, 'Step 1: Find the weekly total'],{visible:()=>jm==1, color:'red',  display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [5.5, 5.5, 'Step 2: Divide the weekly total by number of days'],{visible:()=>jm==2, color:'red', display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
   brd1.create('text', [4.8, -0.5, 'This is Mary\'s weekly total'],{visible:()=>jm==1, color:'red', display:'internal',anchorX:'right', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [4.8, -0.5, 'This is Mary\'s daily average score'],{visible:()=>jm==2, display:'internal',color:'red', anchorX:'right', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('image', ['/assets/finger.svg', [()=>5-(jm-1)*0.1,-0.7],[0.4, 0.4]], {visible:()=>jm>=1, opacity:1, fixed:true});
//
  brd1.create('text', [()=>6+(jm-1)*0.1, -0.5, function(){return ''+ 1*(type1.valueOf()*1+type2.valueOf()*1+1*type3.valueOf()+1*type4.valueOf()+1*type5.valueOf()) + '/5 = '+
  1*(type1.valueOf()*1+type2.valueOf()*1+1*type3.valueOf()+1*type4.valueOf()+1*type5.valueOf())/5 + ' goals' ;}],{visible:()=>jm==2, display:'internal',anchorX:'middle', color:'red', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  brd1.create('text', [6, -0.5, function(){return ''+ 1*(type1.valueOf()*1+type2.valueOf()*1+1*type3.valueOf()+1*type4.valueOf()+1*type5.valueOf()) + ' goals';}],{color:'red', visible:()=>jm==1, display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(20*brd1.canvasWidth/800.)}});
//
  var P3 = drawPoint(brd1, 5, 0);
  P3.setAttribute({visible:false});
  var P4 = drawPoint(brd1, 7, 0);
  P4.setAttribute({visible:false});
  var net =drawSegment(brd1, P3, P4);
  net.setAttribute({visible:()=>jm>=1, strokeColor:'red'});
//////////////////////////////////////////////////////////////////
}
}
export default Boxes;
